
.interactive-element {
  display: inline-block;
  text-decoration: none;
  opacity: 0;
}

.interactive-element:focus, .interactive-element:active {
  outline: none !important;
  box-shadow: none;
}

.interactive-element-hide {
  display: none !important;
}

.video-react .video-react-poster {
  background-size: cover !important;
}

/* .player-container .video-react-controls-enabled {
  padding-top: 16px !important;
} */

.no-big-player-button {
  display: none !important;
}

.player-container {
  border: none;
  margin: 0 auto;
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;
  max-width: 852px;
  position: relative;
}

.video-react-control-bar a.video-react-button {
  padding-top: 7px;
}
.video-react-control-bar a.video-react-button i {
  font-size: 15px;
  color: #fff;
}

@media screen and (min-height: 1000px){ 
  .player-container {
    max-width: 1080px;
  }
}

@media screen and (min-height: 1100px){ 
  .player-container {
    max-width: 1200px;
  }
}

@media screen and (min-height: 1200px){ 
  .player-container {
    max-width: 1440px;
  }
}

/* NOTE: This must come after the @media screen and (min-height...) items above so it
  supersedes the breakpoints and keeps the max-width unset for embeded
*/
.player-embeded {
  max-width: unset;
}
/* -----------
   The share panel dialog that comes up on top of the video
*/
.share-panel{
  position: absolute;
  left: 30%;
  width: 40%;
  top: 10%;
  background: #ffffffee;
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.share-panel .share-panel-header{
  height: 40px;
  background: #0003;
  border-bottom: 1px solid white;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px 5px 0 0;
}
.share-panel .share-panel-header h3{
  font-size: 22px;
  font-weight: 400;
  color: white;
  margin: 0 10px;
}

.share-panel .close-button{
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px; 
  border: 1px solid white;
  border-radius: 50%;
  background: #ffffff99;
  color: black;
  cursor: pointer;
}
.share-panel .close-button i.fa{
  margin-top: 2px;
}

.share-panel .share-list{
  list-style: none;
  text-align: left;
}
.share-panel .share-list li{
  margin-bottom: 10px;
  font-size: 16px;
  /* height: 35px; */
}

.share-panel .share-list li i{
  margin-right: 10px;
}

.share-panel .share-list li a{
  text-decoration: none; 
}

.share-panel .button-circle{
  width: 1.8em !important;
}
.share-panel i.app-icon{
  font-size: 14px;
  background: unset;
  padding: 2.8px 3.5px;
  border-radius: 50%;
}
.share-panel i.app-icon.fa-facebook{
  padding: .2em .4em;
}

.share-panel .progress-container{
  display: inline-block;
  width: 35px;  
  margin-right: 6px;
  margin-left: -5px;
  margin-top: -5px;
}
.share-panel .progress-container i{
  margin-right: 0 !important;
}

@media screen and (max-width: 450px){ 
  .share-panel{
    width: 70%;
    position: absolute;
    left: 15%;
    top: 5px;
  }

  .share-panel .share-list{
    margin-bottom: 0;
  }
  .share-panel .share-list li{
    margin-bottom: 5px;
    font-size: 16px;
    height: 30px;
  }

  .share-panel .share-panel-header{
    height: 25px;
    background: #ffffff33;
    border-bottom: 1px solid white;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .share-panel .share-panel-header h3{
    font-size: 18px;
    font-weight: 400;
    color: white;
    margin: 3px;
  }

  .share-panel .close-button{
    background: none;
    border: none;
    color: white;
    margin-top: -5px;
  }
}

@media screen and (max-height: 450px) and (min-width: 450px){ 
  .share-panel{
    width: 70%;
    position: absolute;
    left: 15%;
    top: 5%;
  }
}